<template>
  <div class="d-flex justify-content-center align-items-center min-vh-100">
    <div class="text-center">
      <div style="display: flex; justify-content: center; align-items: center;margin-bottom:30px;">
          <img src="/img/logo_v_x3.png" alt="e-attach" width="170" height="101" />
      </div>
        <div style="justify-content: center;">
        <center>
          <H1 v-html="_d('error_code_contents_not_found', '404', 'An error code or title about the contents was not found')"></H1>
        <H5 v-html="_d('error_message_contents_not_found', 'コンテンツが見つかりません', 'An error message about the contents was not found')" ></H5>
        </center>
        </div>
    </div>
  </div>
</template>
<script>
import Dictionary from '@/mixins/dictionary';

export default {
  data() {
    return {
      dict_prefix: 'recipient_not_found',
      name: 'Attach',
      is_loaded: false,
      is_error: false,
      loaded_data: {},
      status_label: '',
    };
  },
  mixins: [Dictionary],
  routes: [{
    path: '/:catchAll(.*)', name: 'NotFound', component: this, meta: { requiresAuth: true },
  }],
  computed: {
  },
  components: {

  },
  methods: {
  },
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  mounted() {
    console.log(`${this.name} mounted`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped>
.btn-large {
  margin: 10px 0px 10px 0px;;
  width:300px;
}

</style>
