<template>
  <div v-if="pageState === 'loading'" class="d-flex justify-content-center align-items-center min-vh-100">
    <div class="text-center">
      <h1 v-html="_d('msg_loading_page', '読み込み中...', 'Message informing is loading the page')"></h1>
    </div>
  </div>
  <div v-else-if="pageState === 'processing'" class="d-flex justify-content-center align-items-center min-vh-100">
    <div class="text-center">
      <h1 v-html="_d('msg_processing', '処理中...', 'Message informing is processing')"></h1>
    </div>
  </div>
  <div v-else-if="pageState === 'error'" class="d-flex justify-content-center align-items-center min-vh-100">
    <div class="text-center">
      <h1 v-html="_d('msg_error', 'エラー', 'Message informing about an error')"></h1>
        <div>{{ errorMessage1 }}<br>{{ errorMessage2 }}</div>
    </div>
  </div>
  <RecipientContainer :add_logo="true" v-else-if="pageState === 'ask_for_mfa_code'">
    <!-- An email was sent to the address <b>{{ mfaAddress }}</b>, please check your email and input the received code.<br> -->
    <span v-if="mfaModeMethod === 'mail'" v-html="_d('msg_mfa_mail_send_and_ask_code', 'メールがアドレス <b>{{mfaAddress}}</b> に送信されました。メールを確認して、受信したコードを入力してください。', 'Message to user to informing the MFA email was sent to the address. And asking to add the received code.',{mfaAddress: mfaAddress })"></span>
    <span v-if="mfaModeMethod === 'sms'"  v-html="_d('msg_mfa_sms_send_and_ask_code', 'SMSが番号 <b>{{mfaAddress}}</b> に送信されました。SMSを確認して、受信したコードを入力してください。', 'Message to user to informing the MFA SMS was sent to the number. And asking to add the received code.',{mfaAddress: mfaAddress })"></span>
    <br>
    <input type="text" class="form-control" :placeholder="_d('input_code', 'コードを入力してください', 'Placeholder asking the user to input the code')"
    style="margin-top:20px;max-width: 200px;" v-model="receivedCode" data-cy="mfa-code-input">
    <button style="margin-top:20px;" class="btn btn-primary" @click.prevent="validateMfa" data-cy="validate-button">{{ _d('validate_button', '検証', 'Button for validation') }}</button>
  </RecipientContainer>
  <RecipientContainer :add_logo="true" v-else-if="pageState === 'ask_mfa_multi_mail'" >
    <span v-html="_d('msg_fill_email_defined', '電子メールに入力して認証コードを受信します。<br>送信者によって定義された電子メールのみが許可されます。', 'Message to user to fill the email defined by the sender to receive the MFA.')"></span>
    <br>
    <input type="text" class="form-control"
    :placeholder="_d('input_email_at_multi_mail', 'メールを入力してください', 'Placeholder asking the user to input the email')"
    v-model="mfaAddress"
    style="margin-top:20px;max-width: 400px;" data-cy="mfa-email-input">
    <div style="color:red; margin-top:5px;"
      v-if="emailNotFoundErrMessage?.length > 0" v-html="emailNotFoundErrMessage"></div>
    <button style="margin-top:20px;" class="btn btn-primary" @click.prevent="sendMfaMail(true)" v-html="_d('btn_to_continue_at_multi_mail', '続行', 'A button at MFA to continue')" data-cy="continue-button"></button>
  </RecipientContainer>
  <RecipientContainer :add_logo="true" v-else-if="pageState === 'ask_mfa_multi_sms'" >
    <span v-html="_d('msg_fill_sms_defined', '電話番号に入力して認証コードを受信します。<br>送信者によって定義された電話番号のみが許可されます。', 'Message to user to fill the phone number defined by the sender to receive the MFA.')"></span>
    <br>
    <input type="text" class="form-control"
    :placeholder="_d('input_sms_at_multi_sms', '電話番号を入力してください', 'Placeholder asking the user to input the phone number')"
    v-model="mfaAddress"
    style="margin-top:20px;max-width: 400px;" data-cy="mfa-sms-input">
    <div style="color:red; margin-top:5px;"
      v-if="smsNotFoundErrMessage?.length > 0" v-html="smsNotFoundErrMessage"></div>
    <button style="margin-top:20px;" class="btn btn-primary" @click.prevent="sendMfaSms(true)" v-html="_d('btn_to_continue_at_multi_sms', '続行', 'A button at MFA to continue')" data-cy="continue-button"></button>
  </RecipientContainer>
  <RecipientContainer :add_logo="true" v-else-if="pageState === 'ask_mfa_free_mail'" >

    <span v-html="_d('msg_fill_email_any_is_valid', '電子メールに入力して認証コードを受信します。<br> どの電子メールでも有効です。',  'Message to user to fill to receive the MFA.Any email is valid.')"></span>

    <br>
    <input type="text" class="form-control"
    :placeholder="_d('input_email_at_free_mail', 'メールを入力してください', 'Placeholder asking the user to input the email')"
    v-model="mfaAddress"
    style="margin-top:20px;max-width: 400px;" data-cy="mfa-email-input">
    <button style="margin-top:20px;" class="btn btn-primary" @click.prevent="sendMfaMail(false)" v-html="_d('btn_to_continue_at_free_mail', '続行', 'A button at MFA to continue')" data-cy="continue-button"></button>

  </RecipientContainer>
  <RecipientContainer :add_logo="true" v-else-if="pageState === 'ask_mfa_free_sms'" >

    <span v-html="_d('msg_fill_sms_any_is_valid', '電話番号に入力して認証コードを受信します。<br> どの電話番号でも有効です。',  'Message to user to fill to receive the MFA.Any phone number is valid.')"></span>

    <br>
    <input type="text" class="form-control"
    :placeholder="_d('input_sms_at_free_sms', '電話番号を入力してください', 'Placeholder asking the user to input the phone number')"
    v-model="mfaAddress"
    style="margin-top:20px;max-width: 400px;" data-cy="mfa-sms-input">
    <button style="margin-top:20px;" class="btn btn-primary" @click.prevent="sendMfaSms(false)" v-html="_d('btn_to_continue_at_free_sms', '続行', 'A button at MFA to continue')" data-cy="continue-button"></button>

  </RecipientContainer>
  <RecipientContainer :add_logo="true" v-else-if="pageState === 'ask_mfa_single_mail'" >
    <span style="">
        <!-- We will send an email to to at the address <b>{{ mfaAddress }}</b>, press the below button to continue. -->
        <span v-html="_d('msg_inform_will_send_mfa_mail_single', 'アドレス <b>{{mfaAddress}}</b> にメールを送信します。<br>続行するには下のボタンを押してください。', 'A message to the user, informing we will send an email to the email defined by the sender, the {{mfaAddress}} is where the email will be set',{mfaAddress: mfaAddress })"></span>
        <br>
      </span>
      <br>
      <button style="margin-top:20px;" class="btn btn-primary" @click.prevent="sendMfaMail(false)" v-html="_d('btn_to_continue_at_single_mail', '続行', 'A button at MFA to continue')" data-cy="continue-button"></button>
  </RecipientContainer>
  <RecipientContainer :add_logo="true" v-else-if="pageState === 'ask_mfa_single_sms'" >
    <span style="">
          <!-- We will send an SMS to the number <b>{{ mfaAddress }}</b>, press the below button to continue. -->
          <span v-html="_d('msg_inform_will_send_mfa_sms_single', '番号 <b>{{mfaAddress}}</b> にSMSを送信します。<br>続行するには下のボタンを押してください。', 'A message to the user, informing we will send an SMS to the number defined by the sender, the {{mfaAddress}} is where the SMS will be sent',{mfaAddress: mfaAddress })"></span>
          <br>
        </span>
        <br>
        <button style="margin-top:20px;" class="btn btn-primary" @click.prevent="sendMfaSms(false)" v-html="_d('btn_to_continue_at_single_sms', '続行', 'A button at MFA to continue')" data-cy="continue-button"></button>
  </RecipientContainer>
  <div v-else class="row">
    <div class="text-center">
        <h1>{{_d('unexpected_problem_at_option_selection', '何らかの問題が発生しました', 'An unexpected problem at option selection')}}</h1>
    </div>
  </div>
</template>
<script>
import Dictionary from '@/mixins/dictionary';
import RecipientContainer from '@/components/recipient-container';

// const ATTACH_STATUS = {
//   DRAFT: 10,
//   ACTIVE: 20,
//   PAUSE: 30,
//   DELETED: 40,
//   EXPIRED: 50,
// };
// const ATTACH_STATUS_TEXT = {
//   10: 'DRAFT',
//   20: 'ACTIVE',
//   30: 'PAUSE',
//   40: 'DELETED',
//   50: 'EXPIRED',
// };
export default {
  data() {
    return {
      dict_prefix: 'recipient_mfa',
      name: 'MFA',
      loaded_data: [],
      attach_password: '',
      status_label: '',
      attach_key: '',
      page_state: 'loading',
      session_storage_key: '',
      error_message1: '',
      error_message2: '',
      list_of_files: [],
      session_key: '',
      mfa_address: '',
      link_mode_prefix: '',
      mfa_mode: '',
      received_code: '',
      return_url: '',
      email_not_found_err_message: '',
      mfa_mode_method: '',
    };
  },
  components: {
    RecipientContainer,
  },
  mixins: [Dictionary],
  routes: [
    {
      path: '/mfa-s/:mfa_mode_method/:link_mode_prefix/:key', name: 'MfaSingle', component: this, meta: { requiresAuth: true, mfa_mode: 'single' },
    },
    {
      path: '/mfa-f/:mfa_mode_method/:link_mode_prefix/:key', name: 'MfaFree', component: this, meta: { requiresAuth: true, mfa_mode: 'free' },
    },
    {
      path: '/mfa-m/:mfa_mode_method/:link_mode_prefix/:key', name: 'MfaMulti', component: this, meta: { requiresAuth: true, mfa_mode: 'multi' },
    },
    {
      path: '/wt/mfa-s/:mfa_mode_method/:link_mode_prefix/:key', name: 'WaitMfaSingle', component: this, meta: { requiresAuth: true, mfa_mode: 'wait_single' },
    },
    {
      path: '/wt/mfa-f/:mfa_mode_method/:link_mode_prefix/:key', name: 'WaitMfaFree', component: this, meta: { requiresAuth: true, mfa_mode: 'wait_free' },
    },
    {
      path: '/wt/mfa-m/:mfa_mode_method/:link_mode_prefix/:key', name: 'WaitMfaMulti', component: this, meta: { requiresAuth: true, mfa_mode: 'wait_multi' },
    },
  ],
  computed: {
    mfaModeMethod: {
      get() {
        return this.mfa_mode_method;
      },
      set(v) {
        this.mfa_mode_method = v;
      },
    },
    emailNotFoundErrMessage: {
      get() {
        return this.email_not_found_err_message;
      },
      set(v) {
        this.email_not_found_err_message = v;
      },
    },
    receivedCode: {
      get() {
        return this.received_code;
      },
      set(v) {
        this.received_code = v;
      },
    },
    mfaAddress: {
      get() {
        return this.mfa_address;
      },
      set(v) {
        this.mfa_address = v;
      },
    },
    errorMessage1: {
      get() {
        return this.error_message1;
      },
      set(v) {
        this.error_message1 = v;
      },
    },
    errorMessage2: {
      get() {
        return this.error_message2;
      },
      set(v) {
        this.error_message2 = v;
      },
    },
    pageState: {
      get() {
        return this.page_state;
      },
      set(v) {
        this.page_state = v;
      },
    },
    attachKey: {
      get() {
        return this.attach_key;
      },
      set(v) {
        this.attach_key = v;
      },
    },
  },
  methods: {
    validateMfa() {
      const code = this.receivedCode;
      const api_url = `/recipient/validate_mfa_code/${this.attachKey}`;
      const body = {
        session_key: sessionStorage.getItem(`mfa_${this.session_storage_key}`),
        mfa_session: sessionStorage.getItem(`mfa_session_id_${this.session_storage_key}`),
        mfa_id: sessionStorage.getItem(`mfa_id_${this.session_storage_key}`),
        code,
      };
      this.$ajax.post(api_url, body, (err, _result) => {
        if (err) {
          this.$notify({
            type: 'error',
            title: this._d('unexpected_error_after_mfa_popup_title', '予期しないエラー', 'At login page, a popup for an unexpected error title.'),
            text: err,
          });
          return;
        }
        sessionStorage.setItem(this.session_storage_key, sessionStorage.getItem(`mfa_${this.session_storage_key}`));
        sessionStorage.removeItem(`mfa_${this.session_storage_key}`);
        sessionStorage.removeItem(`mfa_id_${this.session_storage_key}`);
        sessionStorage.removeItem(`mfa_session_id_${this.session_storage_key}`);
        sessionStorage.removeItem(`mfa_mail_${this.session_storage_key}`);
        this.$router.push(this.return_url);
      });
    },
    sendMfaSms(_check_in_list = false) {
      const sms_number = this.mfaAddress;
      this.sms_numberNotFoundErrMessage = '';

      const api_url = `/recipient/mfa_send_sms/${this.attachKey}`;
      const body = {
        session_key: sessionStorage.getItem(`mfa_${this.session_storage_key}`),
        sms_number,
      };
      this.$ajax.post(api_url, body, (err, result) => {
        if (err) {
          this.$notify({
            type: 'error',
            title: this._d('unexpected_error_msg_at_sms_mfa', '予期しないエラー', 'Message informing about an Unexpected error'),
            text: err,
          });
          return;
        }
        if (result.valid_sms_number === true && result.sms_sent === true && result.in_list === true) {
          this.$notify({
            type: 'success',
            title: this._d('sms_sent', 'SMS電話番号が送信されました', 'SMS  sent'),
            text: this._d('sms_sent_to', 'SMS電話番号が次のアドレスに送信されました: {{sms_number}}', 'An SMS was sent to the address: {{sms_number}}', { sms_number }),

          });
          sessionStorage.setItem(`mfa_id_${this.session_storage_key}`, result.mfa_id);
          sessionStorage.setItem(`mfa_session_id_${this.session_storage_key}`, result.mfa_session_id);
          sessionStorage.setItem(`mfa_sms_number_${this.session_storage_key}`, result.to);
          this.pageState = 'ask_for_mfa_code';
          return this.$router.push(`/wt${this.$route.path}`);
        } if (result.not_in_list === true) {
          this.sms_numberNotFoundErrMessage = this._d('sms_number_not_found_in_the_list', 'SMS電話番号<b>{{sms_number}}</b>が見つかりません、別のものを試してください。', 'The sms_number address {{sms_number}} is not found, try another one.', { sms_number });
          this.$notify({
            duration: 3000,
            type: 'error',
            title: this._d('sms_number_not_found_title_pop_up', 'SMS電話番号が見つかりません', 'SMS number not found'),
            text: this.sms_numberNotFoundErrMessage,
          });
          this.mfaAddress = '';
        } else if (result.valid_mfa_session === false) {
          this.$notify({
            type: 'error',
            title: this._d('invalid_session_sms', '無効なセッション', 'Invalid session'),
            text: this._d('invalid_or_expired_session_sms', 'セッションが無効または期限切れです、もう一度お試しください。', 'The session is invalid or expired, please try again.'),
          });
          return this.$router.push(this.return_url);
        } else if (result.in_list === false) {
          this.$notify({
            type: 'error',
            title: this._d('sms_number_not_in_sender_list_title', 'SMS電話番号が見つかりません', 'SMS number not found'),
            text: this._d('sms_number_not_in_sender_list', 'SMS電話番号は送信者が定義したSMS電話番号のリストにありません、もう一度お試しください。', 'The sms_number address is in the list of sms_numbers defined by the sender, please try again.'),
          });
        } else if (result.valid_sms_number === false) {
          this.$notify({
            type: 'error',
            title: this._d('invalid_sms_number', '無効なSMS電話番号', 'Invalid sms_number'),
            text: this._d('invalid_sms_number_try_again', 'SMS電話番号が無効です、もう一度お試しください。', 'The sms_number address is invalid, please try again.'),
          });
        } else {
          this.$notify({
            type: 'error',
            title: this._d('unexpected_error_at_mfa_validation_with_sms_number', '予期しないエラー', 'Unexpected error'),
            text: this._d('unexpected_error_try_again_sms', '予期しないエラーが発生しました、もう一度お試しください。', 'An unexpected error occurred, please try again.'),
          });
        }
      });
    },
    sendMfaMail(_check_in_list = false) {
      const mail = this.mfaAddress;
      this.emailNotFoundErrMessage = '';

      // FIXME: Validate the email address.
      const api_url = `/recipient/mfa_send_mail/${this.attachKey}`;
      const body = {
        session_key: sessionStorage.getItem(`mfa_${this.session_storage_key}`),
        email: mail,
      };
      this.$ajax.post(api_url, body, (err, result) => {
        if (err) {
          this.$notify({
            type: 'error',
            title: this._d('unexpected_error_msg', '予期しないエラー', 'Message informing about an Unexpected error'),
            text: err,
          });
          return;
        }
        if (result.valid_mail === true && result.mail_sent === true && result.in_list === true) {
          this.$notify({
            type: 'success',
            title: this._d('email_sent', 'メールが送信されました', 'Email sent'),
            text: this._d('email_sent_to', 'メールが次のアドレスに送信されました: {{mail}}', 'An email was sent to the address: {{mail}}', { mail }),

          });
          sessionStorage.setItem(`mfa_id_${this.session_storage_key}`, result.mfa_id);
          sessionStorage.setItem(`mfa_session_id_${this.session_storage_key}`, result.mfa_session_id);
          sessionStorage.setItem(`mfa_mail_${this.session_storage_key}`, result.to);
          this.pageState = 'ask_for_mfa_code';
          return this.$router.push(`/wt${this.$route.path}`);
        } if (result.not_in_list === true) {
          this.emailNotFoundErrMessage = this._d('email_not_found_in_the_list', 'メールアドレス<b>{{mail}}</b>が見つかりません、別のものを試してください。', 'The email address {{mail}} is not found, try another one.', { mail });
          this.$notify({
            duration: 3000,
            type: 'error',
            title: this._d('email_not_found_title_pop_up', 'メールが見つかりません', 'Email not found'),
            text: this.emailNotFoundErrMessage,
          });
          this.mfaAddress = '';
        } else if (result.valid_mfa_session === false) {
          this.$notify({
            type: 'error',
            title: this._d('invalid_session', '無効なセッション', 'Invalid session'),
            text: this._d('invalid_or_expired_session', 'セッションが無効または期限切れです、もう一度お試しください。', 'The session is invalid or expired, please try again.'),
          });
          return this.$router.push(this.return_url);
        } else if (result.in_list === false) {
          this.$notify({
            type: 'error',
            title: this._d('email_not_in_sender_list_title', 'メールが見つかりません', 'Email not found'),
            text: this._d('email_not_in_sender_list', 'メールアドレスは送信者が定義したメールのリストにありません、もう一度お試しください。', 'The email address is in the list of emails defined by the sender, please try again.'),
          });
        } else if (result.valid_mail === false) {
          this.$notify({
            type: 'error',
            title: this._d('invalid_email', '無効なメール', 'Invalid email'),
            text: this._d('invalid_email_try_again', 'メールアドレスが無効です、もう一度お試しください。', 'The email address is invalid, please try again.'),
          });
        } else {
          this.$notify({
            type: 'error',
            title: this._d('unexpected_error_at_mfa_validation_with_mail', '予期しないエラー', 'Unexpected error'),
            text: this._d('unexpected_error_try_again', '予期しないエラーが発生しました、もう一度お試しください。', 'An unexpected error occurred, please try again.'),
          });
        }
      });
    },
    onLogout() {
      sessionStorage.removeItem(this.session_storage_key);
      this.$router.push('/completed');
    },
  },
  props: [],
  beforeCreate() {
    console.log(`${this.name} beforeCreate`);
  },
  created() {
    console.log(`${this.name} created`);
  },
  beforeMount() {
    console.log(`${this.name} beforeMount`);
  },
  mounted() {
    console.log('TEST DEBUG 240204 (201 at mfa.vue)[23:56]: ', { });
    if (this.$route.params.key === 'undefined') {
      // redirect to not found page
      this.$router.push('/not_found');
    } else {
      this.attachKey = this.$route.params.key;
      this.link_mode_prefix = this.$route.params.link_mode_prefix;
      this.mfaModeMethod = this.$route.params.mfa_mode_method;
      this.mfa_mode = this.$route.meta.mfa_mode;

      this.return_url = `/${this.link_mode_prefix}/${this.attachKey}`;
      // request ajax to check the mfa status, so if the session already expired, then send back to the password page.
      this.session_storage_key = `attach_key_${this.attachKey}`;

      const api_url = `/recipient/mfa_check_on_mfa/${this.attachKey}`;
      this.session_key = sessionStorage.getItem(`mfa_${this.session_storage_key}`) || '';
      console.log('TEST DEBUG 240204 (216 at mfa.vue)[23:52]: ', { session_key: this.session_key, session_storage_key: this.session_storage_key });
      if (this.session_key === '') {
        return this.$router.push(this.return_url);
      }
      const body = {
        session_key: this.session_key,
      };

      this.$ajax.post(api_url, body, (err, result) => {
        if (err) {
          this.$notify({
            type: 'error',
            title: this._d('unexpected_error_at_mfa_check_on_mfa_api', '予期しないエラー', 'Message informing about an Unexpected error'),
            text: err,
          });
          return;
        }
        if (result.valid_mfa_session === false) {
          this.$notify({
            type: 'error',
            title: this._d('invalid_session_at_mfa_check_on_mfa', '無効なセッション', 'Message informing about an Invalid session'),
            text: this._d('invalid_or_expired_session_at_mfa_check_on_mfa', 'セッションが無効または期限切れです、もう一度お試しください。', 'Message informing the user that the session is invalid or expired and they should try again'),
          });
          return this.$router.push(this.return_url);
        }

        console.log('TEST DEBUG 240204 (205 at mfa.vue)[23:37]: ', { mfa_mode: this.mfa_mode });
        // const session_storage_data = sessionStorage.getItem(`mfa_${this.session_storage_key}`) || false;
        if (this.mfa_mode === 'single') { // Single
          // Show the page to inform that will send the email to email address.
          // mfa_single_address
          this.mfaAddress = result.mfa_single_address;
          this.pageState = (this.mfaModeMethod === 'mail') ? 'ask_mfa_single_mail' : 'ask_mfa_single_sms';
          this.$notify({
            type: 'info',
            title: this._d('mfa_required_single_mail', 'MFAが必要です', 'Message informing the user that MFA is required for single mail'),
            // text: 'ask_mfa_multi_mail',
          });
        } else if (this.mfa_mode === 'multi') { // /multi/
          // Show an input box in the page , asking to type the email.
          this.pageState = (this.mfaModeMethod === 'mail') ? 'ask_mfa_multi_mail' : 'ask_mfa_multi_sms';
          this.$notify({
            type: 'info',
            title: this._d('mfa_required_multi', 'MFAが必要です', 'Message informing the user that MFA is required for multi mail'),
            // text: 'ask_mfa_multi_mail',
          });
        } else if (this.mfa_mode === 'free') { // FRee
          // Show an input box in the page , asking to type any email to receive the notification.
          this.pageState = (this.mfaModeMethod === 'mail') ? 'ask_mfa_free_mail' : 'ask_mfa_free_sms';
          this.$notify({
            type: 'info',
            title: this._d('mfa_required_free', 'MFAが必要です', 'Message informing the user that MFA is required for free/any mail.'),
            // text: 'ask_mfa_free_mail',
          });
        } else if (this.mfa_mode === 'wait_free' || this.mfa_mode === 'wait_single' || this.mfa_mode === 'wait_multi') {
          this.mfaAddress = sessionStorage.getItem(`mfa_mail_${this.session_storage_key}`);
          this.pageState = 'ask_for_mfa_code';
        }
      });
    }
    console.log(`${this.name} mounted`);
  },
  beforeUpdate() {
    console.log(`${this.name} beforeUpdate`);
  },
  updated() {
    console.log(`${this.name} updated`);
  },
  beforeUnmount() {
    console.log(`${this.name} beforeUnmount`);
  },
  unmounted() {
    console.log(`${this.name} unmounted`);
  },
  errorCaptured() {
    console.log(`${this.name} errorCaptured`);
  },
  renderTracked() {

  },
  renderTriggered() {
    console.log(`${this.name} renderTriggered`);
  },
  activated() {
    console.log(`${this.name} activated`);
  },
  deactivated() {
    console.log(`${this.name} deactivated`);
  },
};
</script>
<style scoped>
.btn-large {
  margin: 10px 0px 10px 0px;;
  width:300px;
}
.btn-primary {
    background-color: #6b71f2; /* Primary color */
    border-color: #5a62d2; /* Slightly darker shade for the border */
}

.btn-primary:hover {
    background-color: #6268e0; /* Slightly darker shade for hover */
    border-color: #4f58c2; /* Optionally, you could also darken the border color slightly on hover */
}

.download_tr:hover{
  background-color: #f7f7f7;
}

</style>
