<template>
  <div class="container" style="margin-top:20px;">
    <div class="row" v-if="add_logo == true">
      <div class="col-2"></div>
      <div class="col-8">
        <div style="display: flex; justify-content: center; align-items: center;margin-bottom:30px;">
          <img src="/img/logo_h.png" alt="e-attach" />
        </div>
      </div>
      <div class="col-2"></div>
    </div>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-8">
        <div :style="(center)?'display: grid; place-items: center;':''">
          <slot></slot>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecipientContainer',
  props: {
    msg: String,
    add_logo: Boolean,
    center: Boolean,
  },
};
</script>

<style scoped></style>
